import React, { useState } from 'react';
import { dbService } from '../firebase';
import poster from '../image/poster.png';
import * as dayjs from 'dayjs';

const Use = ({ match }) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [isUsed, setIsUsed] = useState(true);
  const couponData = dbService.collection('coupons').doc(match.params.id);
  couponData.get().then((data) => {
    data.exists ? setIsDeleted(false) : setIsDeleted(true);
    data.data().isUsed ? setIsUsed(true) : setIsUsed(false);
  });
  let date = dayjs();
  const useCoupon = () => {
    const ok = window.confirm(
      '쿠폰을 사용하시겠어요?\n사용 시 매장 직원에게 보여주세요',
    );
    ok &&
      couponData
        .update({
          isUsed: true,
          date: date.format('DD/MM/YYYY HH:mm'),
        })
        .then((v) => {
          console.log(v, 'success');
          setIsUsed(true);
        })
        .catch((err) => {
          console.log(err);
          window.alert('사용할 수 없는 쿠폰입니다.');
        });
  };
  return (
    <div className="mt-8 text-center">
      <img src={poster} alt="poster" className="mx-auto" />
      <div className="w-full mt-12">
        {isUsed ? (
          <div className="flex justify-center items-center text-xl bg-naegaBlack mx-6 h-15 p-3 rounded-md text-gray-300 cursor-pointer">
            이미 사용 완료된 쿠폰입니다.
          </div>
        ) : (
          <div
            className="flex justify-center items-center text-xl bg-naegaBlack mx-6 h-15 p-3 rounded-md text-white cursor-pointer"
            onClick={useCoupon}
          >
            🍺 쿠폰 사용하기
          </div>
        )}
        <a href="https://www.nae-ga.com/" target="_blank" rel="noreferrer">
          <div className="flex justify-center items-center text-xl mt-4 mx-6 h-15 p-3 rounded-md text-naegaBlack cursor-pointer border-2 border-naegaBlack">
            내-가전 홈페이지 가기
          </div>
        </a>
      </div>
      <div className="text-left ml-3 md:ml-40 mt-12 mb-28 text-textBlack test-sm">
        <p>
          ※ 본 쿠폰은 전시 기간(2021년 7월 17일~8월 15일)동안만 사용 가능합니다.
        </p>
        <p>※ 본 쿠폰은 양도 및 매매가 불가합니다.</p>
        <p>※ 본 쿠폰은 맥주 한잔을 무료로 제공해드립니다.</p>
        <p>※ 쿠폰 문의는 ilhyuk@s2cond.com을 통해 해주세요.</p>
        <p>
          ※ 다음 전시회에 관심이 있으신 분은 인스타그램 @nae._.ga으로 DM 주세요.
        </p>
      </div>
    </div>
  );
};

export default Use;
