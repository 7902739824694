import React from 'react';

const AdminCouponList = ({ list }) => {
  return (
    <div className="flex items-center justify-around mt-5">
      <p className="w-16">{list.id}</p>
      <p className="w-12 ">{list.isUsed ? '⭕️' : '❌'}</p>
      <p className="w-40">{list.date || '미사용'}</p>
    </div>
  );
};

export default AdminCouponList;
