import { Switch, Route } from 'react-router-dom';
import Admin from './pages/Admin';
import Use from './pages/Use';
import Publish from './pages/Publish';
import Home from './pages/Home';

const App = () => {
  return (
    <div className="absolute mt-0 mx-2 lg:mx-24 top-0 left-0">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/ticket/admin" exact component={Admin} />
        <Route path="/ticket/publish" exact component={Publish} />
        <Route path="/ticket/use/:id" component={Use} />
      </Switch>
    </div>
  );
};

export default App;
