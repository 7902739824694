const copy = (ref) => {
  const text = ref.current.innerHTML;
  let t = document.createElement('textarea');
  document.body.appendChild(t);
  t.value = text;
  t.select();
  document.execCommand('copy');
  document.body.removeChild(t);
};

export default copy;
