import React, { useEffect, useRef, useState } from 'react';
import PublishedCustomer from '../components/PublishedCustomer';
import { dbService } from '../firebase';
import CopyImg from '../image/copy.png';
import copy from '../utils/copy';

const Publish = () => {
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [list, setList] = useState([]);
  const publishedLink = useRef(null);
  const [usedCoupons, setUsedCoupons] = useState(0);
  let tmpCoupons = 0;
  const makeInvitation = () => {
    if (!name) return;
    let randomNum = Math.floor(Math.random() * 1000000);
    setLink(`https://ticket.nae-ga.com/ticket/use/${randomNum}`);
    dbService.collection('coupons').doc(randomNum.toString()).set({
      id: randomNum,
      username: name,
      isUsed: false,
      date: '',
    });
  };

  useEffect(() => {
    dbService.collection('coupons').onSnapshot((snapshot) => {
      const newArr = snapshot.docs.map((doc) => doc.data());
      setList(newArr);
    });
    setUsedCoupons(tmpCoupons);
  }, [link, tmpCoupons]);

  return (
    <div className="flex justify-center mt-28">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <p className="mr-5 text-xs lg:text-base">초대하고 싶은 사람 이름:</p>
          <input
            className="border-1 w-18 lg:w-32 rounded-sm mr-3 focus:bg-blue-100"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <button
            onClick={makeInvitation}
            className="bg-blue-200 p-3 sm:text-xs lg:text-base rounded-md hover:bg-blue-100 active:bg-green-200"
          >
            초대장 생성
          </button>
        </div>
        {/* 링크 생성됨 */}
        {link && (
          <div className="flex justify-center items-center mt-8 space">
            <p
              className="p-2 rounded-md hover:bg-green-200 active:bg-green-300"
              ref={publishedLink}
              onClick={() => copy(publishedLink)}
            >
              {link}
            </p>

            <button
              className="bg-transparent border-0 w-10 p-2 rounded-md hover:bg-green-200 active:bg-green-400"
              onClick={() => copy(publishedLink)}
            >
              <img src={CopyImg} alt="copy" className="w-8" />
            </button>
          </div>
        )}
        {/* 생성된 쿠폰 리스트 */}
        <div className="mt-18 flex justify-center">
          {usedCoupons} / {list.length}
        </div>
        <div className="mt-3">
          <div className="flex items-center justify-around mt-3">
            <p>이름</p>
            <p>링크</p>
            <p>사용여부</p>
            <p>사용일자</p>
          </div>
          {list !== [] &&
            list.map((coupon, i) => {
              coupon.isUsed && tmpCoupons++;
              return <PublishedCustomer list={coupon} key={i} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default Publish;
