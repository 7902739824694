import React, { useEffect, useState } from 'react';
import AdminCouponList from '../components/AdminCouponList';
import { dbService } from '../firebase';

const Admin = () => {
  const [list, setList] = useState([]);
  const [usedCoupons, setUsedCoupons] = useState(0);

  let tmpCoupons = 0;

  useEffect(() => {
    dbService.collection('coupons').onSnapshot((snapshot) => {
      const newArr = snapshot.docs.map((doc) => doc.data());
      setList(newArr);
    });
    setUsedCoupons(tmpCoupons);
  }, [tmpCoupons]);

  return (
    <div className="mt-28">
      <p className="lg:text-3xl text-blue-700">
        <b>버드나무 브루어리</b> Admin Page
      </p>
      <div className="mt-3">
        {usedCoupons} / {list.length}
      </div>

      <div className="mt-10 text-center">
        <div className="flex items-center justify-around mt-5 text-lg text-blue-800">
          <p className="w-20">사용 ID</p>
          <p className="w-20">사용 여부</p>
          <p className="w-40">사용 일자</p>
        </div>
        {list &&
          list.map((coupon) => {
            coupon.isUsed && tmpCoupons++;
            console.log(list, coupon);
            return <AdminCouponList list={coupon} key={coupon.id} />;
          })}
      </div>
    </div>
  );
};

export default Admin;
