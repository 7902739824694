import React, { useRef } from 'react';
import { dbService } from '../firebase';
import copy from '../utils/copy';

const PublishedCustomer = ({ list }) => {
  const couponLink = useRef(null);

  const deleteCoupon = () => {
    const ok = window.confirm('쿠폰을 삭제하시겠습니까?');
    ok &&
      dbService
        .collection('coupons')
        .where('id', '==', list.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs[0].ref.delete();
        })
        .then(() => {
          console.log(list.username + '의 쿠폰이 삭제됨');
        })
        .catch((err) => {
          console.log(err);
        });
  };
  return (
    <div className="flex items-center justify-around mt-3 text-xs md:text-base">
      <p>{list.username}</p>
      <p
        className="p-2 rounded-md hover:bg-green-200 active:bg-green-300"
        onClick={() => copy(couponLink)}
        ref={couponLink}
      >
        https://ticket.nae-ga.com/ticket/use/{list.id}
      </p>
      <p>{list.isUsed ? 'YES' : 'NO'}</p>
      {list.date ? (
        <p>{list.date}</p>
      ) : (
        <button
          className="bg-red-300 text-white rounded-lg py-1 px-2 mx-10"
          onClick={deleteCoupon}
        >
          삭제
        </button>
      )}
    </div>
  );
};

export default PublishedCustomer;
